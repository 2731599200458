import { Link } from "react-router-dom";
import {useState, useEffect} from "react";
import logo from '../image/logo.jpg';
import './Navbar.css';
import { Button } from "./Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faMobileRetro} from '@fortawesome/free-solid-svg-icons';



function Navbar({texts,handleLanguage}) {

  const [click,setClick]=useState(false);
  const [button,setButton] = useState(true);

  const handleClick = ()=>setClick(!click);
  const closeMobileMenu=()=>setClick(false);
  //className tiene una propiedad que se llama isActive y ocupo un operador ternario para llamar el active de navnar.css
  
  const showButton=()=>{ 
    if (window.innerWidth <= 960){
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(()=>{

   showButton();  
  },[ ]);
  

  window.addEventListener('resize',showButton);
  // windows va a estar escuchando el resize (el tamaño de la pantalla) y cuando la toque va a usar la funcion showButton
  
  
  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'> 
            <Link to="/" className='navbar-logo' onClick={closeMobileMenu}>
              <img src={logo} alt="logo" className='App-logo' width="65%"/>
            </Link>*/
            <div className='menu-icon' onClick={handleClick}>
               <i className={click ? 'fas fa-times' : 'fas fa-bars'}/> 
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
               <li className='nav-item'>
                  <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                       {texts.menuHome}
                  </Link>
               </li>
               <li className='nav-item'>
                  <Link to='/service' className='nav-links' onClick={closeMobileMenu}>
                       {texts.menuService}
                  </Link>
               </li>
               <li className='nav-item'>
                  <Link to='/ubicacion' className='nav-links' onClick={closeMobileMenu}>
                       {texts.menuLocation}
                  </Link>
               </li>
              {/*<li className='nav-item'>
                  <Link to='/About' className='nav-links' onClick={closeMobileMenu}>
                       About 
                  </Link>
  </li>*/}
               <li>
                  <Link to='/sign-up' className='nav-links-mobile' onClick={closeMobileMenu}>
                       Sign-Up
                  </Link>
               </li>
            </ul>
              
            
               
            {button &&<Button buttonStyle='btn--outline'>SIGN UP</Button>}

            <select name='language' onChange={handleLanguage} className='nav-select'>
                       <option value='es'>Español</option>
                       <option value='en'>English</option>
                       <option value='it'>Italian</option>
             </select>

        </div>
      </nav>
    </>
  )
}

export default Navbar;