import './HeroSection.css';

function HeroSection() {
  return (
    <div className='hero-container'>
         <video src='/video/boreal.mp4' autoPlay loop muted playsInline /> 
      
    </div>
  );
}
export default HeroSection;