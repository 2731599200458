import './Modal.css';


const Modal = ({children,isOpen, closeModal}) => {
  // la propiedad children es como hacer un pan, le puedes meter todo adentro la modal seria el envoltorio
   /*<button className="modal-close" onClick={closeModal}>X</button> */
  return (
    <article className={`modal ${isOpen && "is-open"}`}>{/*cuando is open es verdadero efecuta clase is-open */}
        <div className="modal-container">
        <button className="modal-close" onClick={closeModal}>X</button>
            {children}
        </div>
    </article>
  )
};

export default Modal;