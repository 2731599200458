import React from 'react';
import './ServicesPage.css';




function ServicesPage ( {texts}) {
  return (
    <div className="services">
        <h1> {texts.menuService}</h1>
         <div className="services__container">
            <div className="services__wrapper">
                <ul className="services__items">
                   {/*<ServiciosItems src="./images/instalacion.jpg" text="explorar" label="adventure"  />*/}
                   <li className="service__item">                       
                       <div className="service__item__link">
                          <figure className="service__item__pic-wrap" data-category={texts.serviciosVidrioAutomotrizLabel}>
                            <img src="./images/instalacion.jpg" alt="instalacion" className="service__item__img"/>    
                          </figure>   
                          <div className="service__item__info">
                              <h5 className="service__item__text">
                                {texts.serviciosVidrioAutomotrizContenido}
                              </h5>
                          </div>
                       </div>
                    </li>
                    <li className="service__item">
                       <div className="service__item__link">
                          <figure className="service__item__pic-wrap" data-category={texts.servicioVidrioMaquinariaLabel}>
                            <img src="./images/retro2.jpg" alt="retroexcavadora" className="service__item__img"/>    
                          </figure>   
                          <div className="service__item__info">
                              <h5 className="service__item__text">
                                 {texts.servicioVidrioMaquinariaContenido}
                              </h5>
                          </div>
                        </div>
                     </li>
                    
                </ul>
                <ul className="services__items">
                   {/*<ServiciosItems src="./images/servicioinstalacion.jpg" text="explorar" label="adventure"  />*/}
                   <li className="service__item">                       
                       <div className="service__item__link">
                          <figure className="service__item__pic-wrap" data-category={texts.servicioInstalacionProfesionalLabel}>
                            <img src="./images/servicioinstalacion.jpg" alt="travel imag" className="service__item__img"/>    
                          </figure>   
                          <div className="service__item__info">
                              <h5 className="service__item__text">
                                  {texts.servicioInstalacionProfesionalContenido}   
                              </h5>
                          </div>
                       </div>
                    </li>
                    <li className="service__item">
                       <div className="service__item__link">
                          <figure className="service__item__pic-wrap" data-category={texts.servicioReparacionProfesionalLabel}>
                            <img src="./images/reparacion.jpg" alt="travel imag" className="service__item__img"/>    
                          </figure>   
                          <div className="service__item__info">
                              <h5 className="service__item__text">
                                   {texts.servicioReparacionProfesionalContenido}    
                              </h5>
                          </div>
                        </div>
                     </li>
                     <li className="service__item">
                       <div className="service__item__link">
                          <figure className="service__item__pic-wrap" data-category={texts.servicioInstalacionLaminaSeguridadLabel}>
                            <img src="./images/Laminaantiexplosivas.jpg" alt="travel imag" className="service__item__img"/>    
                          </figure>   
                          <div className="service__item__info">
                              <h5 className="service__item__text">
                                         {texts.servicioInstalacionLaminaSeguridadContenido}
                              </h5>
                          </div>
                        </div>
                     </li>

                </ul> 
             </div>
          </div>
    </div>
  )
};

export default ServicesPage;