import React from 'react'

const MapaCopiapoWaze = () => {
  return (
    <div><iframe src="https://embed.waze.com/iframe?zoom=16&lat=-27.363310&lon=-70.323230&ct=livemap" title='Copiapo' width="400" height="400" allowfullscreen></iframe>



    </div>
  )
}

export default MapaCopiapoWaze;