import Navbar from "./components/Navbar";
import {Routes,Route, HashRouter} from 'react-router-dom'; /*antes en vez de hashrouter tenia un browserRouter */
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import NotFoundpage from "./pages/NotFoundpage";
import ServicesPage from './pages/ServicesPage';
import UbicacionPage from './pages/UbicacionPage';
import {useState} from 'react';
import './App.css';
/*import ScrollToTop from "./components/ScrollToTop";*/
/*import Cards from "./components/Cards";*/

const initialLanguage ="es";

const translations={
  es:{
    menuHome:"Inicio",
    menuService:"Servicios",
    menuLocation:"Ubicacion",
    cardUbication:"Ubicacion",
    cardAddress:"Direccion: ",
    cardOpen:"Horario: Lunes-Viernes ",
    cardOpenWeek:"Sabado: ",
    cardMovil:"Telefono: ",
    cardCel:"Celular: ",
    cardLocation:"Ubicacion",
    footerAllrights:"@2022 Todos los derechos reservados | srz |",
    serviciosVidrioAutomotrizLabel:"Instalacion de vidrio automotriz",
    serviciosVidrioAutomotrizContenido:"Nosotros instalamos cualquier tipo de parabrisas, lunetas, vidrio lateral, de cualquier marca y modelo. Utilizamos los ultimos estandares de la industria del vidrio autmotriz",
    servicioVidrioMaquinariaLabel:"Instalacion de vidrios para maquinaria pesada",
    servicioVidrioMaquinariaContenido:"Nosotros somos especialistas en instalacion de vidrios de maquinaria pesada, parabrisas, vidrios laterales, vidrios laminados para excavadoras, bulldozers, cargadores",
    servicioInstalacionProfesionalLabel:"Servicio instalacion profesional ",
    servicioInstalacionProfesionalContenido:" Si usted tiene el vidrio y necesita instalarlo, nosotros brindamos servicios profesionales de instalacion,realizando una instalacion profesional con los productos de mas alta calidad",
    servicioReparacionProfesionalLabel:"Servicio de reparacion de vidrio automotriz ",
    servicioReparacionProfesionalContenido:"Si usted tiene problemas con algun vidrio de su automovil, nuestros profesionales especializados en vidrios, haran una evaluacion para determinar una solucion para su problema.",
    servicioInstalacionLaminaSeguridadLabel: "Laminas de seguridad para su vehiculo",
    servicioInstalacionLaminaSeguridadContenido:" Nosotros suministramos y instalamos los mejores productos de el mercado. Las laminas de seguridad puede ser aplicada al vidrio existente, endureciendo el vidrio y haciendolo mas resistente a quiebres",
  
  },
  en:{
    menuHome:"Home",
    menuService:"Services",
    menuLocation:"Location",
    cardUbication:"Location",
    cardAddress:"Address: ",
    cardOpen:"Opening hours: Monday-Friday ",
    cardOpenWeek:"Saturday: ",
    cardMovil:"Telephone:" ,
    cardCel:"Mobile: ",    
    cardLocation:"Location",
    footerAllrights:"@2022 All right reserved | srz |",
    serviciosVidrioAutomotrizLabel:"Car glass replacement",
    serviciosVidrioAutomotrizContenido:"We replace any type of windscreen, rear window, side window on any car make and model. We utilize the up-to-date car glass industry standards.",
    servicioVidrioMaquinariaLabel:"Replacement glass for heavy equipment",
    servicioVidrioMaquinariaContenido:"We are specialized in heavy equipment glass replacement, windscreen, side window, laminated glass, for excavators, bulldozers ,loaders",
    servicioInstalacionProfesionalLabel:"Car glass replacement service",
    servicioInstalacionProfesionalContenido:"If you have the glass car,and you need to install, we can offer the service of our specialist glass professionals, using the highest quality adhesive, any kind of make, model or year.",
    servicioReparacionProfesionalLabel:"Car glass repair service ",
    servicioReparacionProfesionalContenido:"If you have problems with your glass car, our specialist glass professional, will do a assessment to determine a solution for your problem.",
    servicioInstalacionLaminaSeguridadLabel: "Security windows film",
    servicioInstalacionLaminaSeguridadContenido:" We supply and install the best vehicle security products on the market, security window film can be applied to existing glass, strengthening the glass and making it more resistant to breakage",
  },
  it:{
    menuHome:"Inizio",
    menuService:"Servizi",
    menuLocation:"Posizione",
    cardUbication:"Posizione",
    cardAddress:"Atenzzione: ",
    cardOpen:"Orari di apertura: Lunedi-Venerdi ",
    cardOpenWeek:"Sabato: ",
    cardMovil:"Telefono:" ,
    cardCel:"Cellulare: ",    
    cardLocation:"Posizione",
    footerAllrights:"@2022 tutti i diritti sono riservati. | srz |",
    serviciosVidrioAutomotrizLabel:"Sostituizione vetro automobilistico",
    serviciosVidrioAutomotrizContenido:"Noi sostituiamo qualsiasi genere di parabrezza, lunotto e laterali, di qualunque marche e modello. Noi usiamo il piu alti standar di il industria automotrice.",
    servicioVidrioMaquinariaLabel:"Sostituzione vetro industria mineraria ",
    servicioVidrioMaquinariaContenido:"Noi siamo specialisti di sostituzione di vetro di mineraria, parabrezza, vetro laterali , vetro per escavadore, bulldozers, caricatore.",
    servicioInstalacionProfesionalLabel:"Servizi sostituzione professionale",
    servicioInstalacionProfesionalContenido:" Se lei avere il vetro e necessita assistenza, noi brindiamo servizio professionale di sostituzione,facendo un sostituzione professionale con gli prodotti di piu alta qualita.",
    servicioReparacionProfesionalLabel:"Servizi di riparazione di vetri ",
    servicioReparacionProfesionalContenido:"Se lei avere problemi con alcuno vetro di su auto, nostro professionale, faranno una valutazione per determinare una soluzione per suo problema.",
    servicioInstalacionLaminaSeguridadLabel: "Pellicole di sicurezza",
    servicioInstalacionLaminaSeguridadContenido:" Noi provvedere e sostituzione gli migliori prodotti de il mercato. Le pellicole di sicurezza puo essere aplicato esistente, facendo il vetro piu resistente.",
  },
};

function App() {

  const[language,setLanguage]= useState(initialLanguage);
  const[texts,setTexts]= useState(translations[language]);
  
  const handleLanguage =(e)=>{
    if(e.target.value==="es")
    {
      setLanguage("es");
      setTexts(translations.es);
    }else if(e.target.value==="en")
    {
      setLanguage("en");
      setTexts(translations.en);
    } else{
      setLanguage("it");
      setTexts(translations.it);
    };
  
  }; 


  
  return (
    <HashRouter>
     
         
         <Navbar texts={texts} handleLanguage={handleLanguage}/>
         <Routes>
           <Route path="/" element={<HomePage texts={texts}/>}/>
           <Route path="/service" element={<ServicesPage texts={texts}/>}/>
           <Route path="/ubicacion" element={<UbicacionPage texts={texts}/>}/>
           <Route path="/about" element={<AboutPage/>}/>
           <Route path= '*' element={<NotFoundpage/>}/>
         </Routes>
   </HashRouter>     
     
  );
}

export default App;
