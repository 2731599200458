import React from 'react'

function MapaLaserenaWase() {
  return (
    <div>  
       <iframe src="https://embed.waze.com/iframe?zoom=16&lat=-29.909283&lon=-71.254096&ct=livemap" title="La serena"width="400" height="400" allowfullscreen></iframe>
   </div>
    )
}

export default MapaLaserenaWase