import './UbicacionPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faMobileRetro, faPhone} from '@fortawesome/free-solid-svg-icons';
import Modal from '../components/Modal'; 
import {useModal} from '../hooks/useModal';

import MapaCoquimboWaze from '../maps/MapaCoquimboWaze';
import MapaLaserenaWaze from '../maps/MapaLaserenaWase';
import MapaCopiapoWaze from '../maps/MapaCopiapoWaze';

const UbicacionPage = ({texts}) => {

  const[isOpenModalCoquimbo,openModalCoquimbo,closeModalCoquimbo]=useModal(false);// esto lo traigo de useModal le cambio el nombre alla es [isOpen,openModal,closeModal] en un arreglo
  const[isOpenModalLaserena,openModalLaserena,closeModalLaserena]=useModal(false);
  const[isOpenModalCopiapo,openModalCopiapo,closeModalCopiapo]=useModal(false);
 
 return (
<div className="ubicacion">
    <h1> {texts.menuLocation}</h1>
  <div className="ubicaciones__container">
    <div className="ubicaciones__wrapper">
        <ul className="ubicaciones__items">
               {/*ACA VIENE COQUIMBO*/}
               <li className="ubicacion__item">                       
                   <div className="ubicacion__item__link">
                      <figure className="ubicacion__item__pic-wrap" data-category="Coquimbo">
                        <img src="./images/iconocoquimbo.jpg" alt="instalacion" className="ubicacion__item__img"/>    
                      </figure>   
                      <div className="ubicacion__item__info">
        
                           <h4 className='cards__item__text'>{texts.cardAddress} Avenida alessandri 861 el llano</h4>
                           <h5 className='cards__item__text'>{texts.cardOpen} 09:00am - 17:30pm</h5>
                           <h5 className='cards__item__text'>{texts.cardOpenWeek} 09:00am - 14:00pm</h5>
                           <h5 className='cards__item__text'>{texts.cardMovil} +56 9 98856386</h5>
                           <h5 className='cards__item__text'>{texts.cardCel} +56 9 92399682</h5>
                      </div>
                      <div className='ubicaciones-btns'>
                          <button className='ubicacion-btn' onClick={openModalCoquimbo} >{texts.cardLocation}</button>
                     </div>
                   </div>
                   <Modal isOpen={isOpenModalCoquimbo} closeModal={closeModalCoquimbo}> 
                       
                      <MapaCoquimboWaze/>
                      <div className='hola'>
                            <div className='ubicaciones-btns' >
                               <a href='https://www.waze.com/en/live-map/directions/cl/coquimbo/coquimbo/av.-pdte.-arturo-alessandri-861?place=EjBBdi4gUGR0ZS4gQXJ0dXJvIEFsZXNzYW5kcmkgODYxLCBDb3F1aW1ibywgQ2hpbGUiMRIvChQKEglHn_Z14ciRlhFAceWeOfHNpxDdBioUChIJFY-DPN7IkZYRTu3YOwBVahE' target='_blank' rel='noreferrer' className='btn-mobile'>
                                   <button className='ubicacion-btn'> Ir al local </button>
                               </a>
                            </div>
                            <div className='iconos'>
                               <FontAwesomeIcon icon={faPhone}/>
                                <a href="tel:+56998856386"> 
                                   +56 9 98856386
                                </a>{/*con tel le indico que es un telefono, este es un celular*/} 
                            </div>
                            <div className='iconos'>
                               <FontAwesomeIcon icon={faMobileRetro}/>
                                <a href="tel:+56992399682">
                                    +56 9 92399682
                                 </a>{/*con tel le indico que es un telefono, este es numero de casa */}
                            </div>
                      </div>
                  </Modal>

                </li>
                {/*ACA VIENE LA SERENA */}
                <li className="ubicacion__item">
                   <div className="ubicacion__item__link">
                      <figure className="ubicacion__item__pic-wrap" data-category="La serena">
                        <img src="./images/iconolaserena.jpg" alt="retroexcavadora" className="ubicacion__item__img"/>    
                      </figure>   
                      <div className="ubicacion__item__info">
              
                           <h5 className='cards__item__text'>{texts.cardAddress} Andres bello La Serena #1075</h5>
                           <h5 className='cards__item__text'>{texts.cardOpen} 09:00am - 18:00pm</h5>
                           <h5 className='cards__item__text'>{texts.cardMovil} +56 51 254 6896</h5>
                           <h5 className='cards__item__text'>{texts.cardCel} +56 9 52524178</h5>
                      </div>
                      <div className='ubicaciones-btns'>
                          <button className='ubicacion-btn' onClick={openModalLaserena} >{texts.cardLocation}</button>
                      </div>
                    </div>
                    <Modal isOpen={isOpenModalLaserena} closeModal={closeModalLaserena}> 
                       
                       <MapaLaserenaWaze/>
                       <div className='hola'>
                             <div className='ubicaciones-btns' >
                                <a href='https://www.waze.com/en/live-map/directions/cl/coquimbo/la-serena/central-parabrisas?place=ChIJeyqgBW_KkZYR_RTRczma1es' target='_blank' rel='noreferrer' className='btn-mobile'>
                                    <button className='ubicacion-btn'> Ir al local </button>
                                </a>
                             </div>
                             <div className='iconos'>
                                <FontAwesomeIcon icon={faPhone}/>
                                 <a href="tel:+56952524178"> 
                                      +56 9 52524178
                                 </a>{/*con tel le indico que es un telefono, este es un celular*/} 
                             </div>
                             <div className='iconos'>
                                <FontAwesomeIcon icon={faMobileRetro}/>
                                 <a href="tel:+56512546896">
                                    +56 51 2546896
                                  </a>{/*con tel le indico que es un telefono, este es numero de casa */}
                             </div>
                       </div>
                   </Modal>
                 </li>
                
         </ul>
         <ul className="ubicaciones__items">
               {/*ACA VIENE VALLENAR*/}
               <li className="ubicacion__item">                       
                   <div className="ubicacion__item__link">
                      <figure className="ubicacion__item__pic-wrap" data-category="Vallenar">
                        <img src="./images/iconovallenar.jpg" alt="travel imag" className="ubicacion__item__img"/>    
                      </figure>   
                      <div className="ubicacion__item__info">
                           <h5 className='cards__item__text'>{texts.cardAddress} Erasmo escala 760b</h5>
                           <h5 className='cards__item__text'>{texts.cardOpen} 09:00am 17:00pm</h5>
                           <h5 className='cards__item__text'>{texts.cardMovil} +56 9 98856386</h5>
                      </div>
                      <div className='ubicaciones-btns'>
                          <button className='ubicacion-btn'  >{texts.cardLocation}</button>
                      </div>
                   </div>
                </li>
                {/*ACA VIENE COPIAPO*/}
                <li className="ubicacion__item">
                   <div className="ubicacion__item__link">
                      <figure className="ubicacion__item__pic-wrap" data-category="Copiapo">
                        <img src="./images/iconocopiapo.jpg" alt="travel imag" className="ubicacion__item__img"/>    
                      </figure>   
                      <div className="ubicacion__item__info">
                           <h5 className='cards__item__text'>{texts.cardAddress} Miguel gallo #1034</h5>
                           <h5 className='cards__item__text'>{texts.cardOpen} Mañana:08:30am - 13:30pm  Tarde:14:00pm - 18:00pm</h5>
                           <h5 className='cards__item__text'>{texts.cardMovil} +56 52 2535615</h5>
                           <h5 className='cards__item__text'>{texts.cardCel} +56 9 90995412</h5>
                      </div>
                      <div className='ubicaciones-btns'>
                          <button className='ubicacion-btn' onClick={openModalCopiapo} >{texts.cardLocation}</button>
                      </div>
                    </div>
                    <Modal isOpen={isOpenModalCopiapo} closeModal={closeModalCopiapo}> 
                       <MapaCopiapoWaze/>
                       <div className='hola'>
                             <div className='ubicaciones-btns' >
                                <a href='https://www.waze.com/en/live-map/directions/cl/atacama/copiapo/miguel-gallo-1034?place=ChIJl3L_7m0EmJYRzNkLGYaEyBE' target='_blank' rel='noreferrer' className='btn-mobile'>
                                    <button className='ubicacion-btn'> Ir al local </button>
                                </a>
                             </div>
                             <div className='iconos'>
                                <FontAwesomeIcon icon={faPhone}/>
                                 <a href="tel:+56998856386"> 
                                    +56 9 98856386
                                 </a>{/*con tel le indico que es un telefono, este es un celular*/} 
                             </div>
                             <div className='iconos'>
                                <FontAwesomeIcon icon={faMobileRetro}/>
                                 <a href="tel:+56992399682">
                                     +56 9 92399682
                                  </a>{/*con tel le indico que es un telefono, este es numero de casa */}
                             </div>
                       </div>
                   </Modal>
                </li>
                {/*ACA VIENE OVALLE*/}

                <li className="ubicacion__item">
                   <div className="ubicacion__item__link">
                      <figure className="ubicacion__item__pic-wrap" data-category="Ovalle">
                        <img src="./images/iconoovalle.jpg" alt="travel imag" className="ubicacion__item__img"/>    
                      </figure>   
                      <div className="ubicacion__item__info">
                           <h5 className='cards__item__text'>{texts.cardAddress} Ovalle</h5>
                           <h5 className='cards__item__text'>{texts.cardOpen} 09:00am 17:00pm</h5>
                           <h5 className='cards__item__text'>{texts.cardMovil} +56 9 98856386</h5>
                      </div>
                      <div className='ubicaciones-btns'>
                          <button className='ubicacion-btn' >{texts.cardLocation}</button>
                      </div>
                    </div>
                </li>

          </ul> 
    </div>
  </div>
</div>
)
};


export default UbicacionPage;