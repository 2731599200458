import React from 'react'
import Cards from '../components/Cards';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';

function HomePage({texts}) {
  return (
    <div>
            <HeroSection  />
            <Cards texts={texts} />
            <Footer texts={texts}/>
    </div>
  )
}

export default HomePage;