
import { useModal } from '../hooks/useModal';
import MapaCopiapoWaze from '../maps/MapaCopiapoWaze';
import MapaCoquimboWaze from '../maps/MapaCoquimboWaze';
import MapaLaserenaWase from '../maps/MapaLaserenaWase';
import CardItem from './CardItem';
import './Cards.css';
import Modal from './Modal'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faMobileRetro, faPhone} from '@fortawesome/free-solid-svg-icons';

function Cards({texts}) {

  const[isOpenModalLaserena,openModalLaserena,closeModalLaserena]=useModal(false);// esto lo traigo de useModal le cambio el nombre alla es [isOpen,openModal,closeModal] en un arreglo
  const[isOpenModalCoquimbo,openModalCoquimbo,closeModalCoquimbo]=useModal(false);
  const[isOpenModalCopiapo,openModalCopiapo,closeModalCopiapo]=useModal(false);
  
  

  return (
    <div className='cards'>
        <h1> {texts.cardUbication}  </h1>
        <div className='cards__container'> 
             <div className='cards__wrapper'>
                 <ul className='cards__items'>
                     <CardItem src="./images/iconolaserena.jpg" cardAddress={texts.cardAddress}  direccion="Andres bello La Serena #1075" cardOpen={texts.cardOpen} horario="09:00am - 18:00pm" cardMovil={texts.cardMovil} telefono="+56 51 254 6896" cardCel={texts.cardCel} telefono2="+56 9 52524178" cardLocation={texts.cardLocation} lavel="La serena" igualdad=" " onClick={openModalLaserena}/>
                         <Modal isOpen={isOpenModalLaserena} closeModal={closeModalLaserena}>
                            <h1>LA SERENA</h1>
                             <MapaLaserenaWase/>
                             <div className='hola'>
                                <a href='https://www.waze.com/en/live-map/directions/cl/coquimbo/la-serena/central-parabrisas?place=ChIJeyqgBW_KkZYR_RTRczma1es' target='_blank' rel='noreferrer' className='btn-mobile'>
                                  <button className='card-btn'> Ir al local </button>                                  
                                </a>
                              </div>
                             <div className='iconos'>
                                  <FontAwesomeIcon icon={faPhone}/>
                                  <a href="tel:+56952524178"> 
                                  +56 9 52524178
                                  </a>{/*con tel le indico que es un telefono, este es un celular*/} 
                              </div>
                              <div className='iconos'>
                                <FontAwesomeIcon icon={faMobileRetro}/>
                                <a href="tel:+56512546896">+56 51 2546896</a>{/*con tel le indico que es un telefono, este es numero de casa */}
                              </div>
                       </Modal>
                     <CardItem src="./images/iconocoquimbo.jpg" cardAddress={texts.cardAddress}  direccion="Avenida alessandri 861 el llano," cardOpen={texts.cardOpen} horario="09:00am - 17:30pm" cardOpenWeek={texts.cardOpenWeek} horario2="09:00am - 14:00pm" cardMovil={texts.cardMovil} telefono="+56 9 98856386" cardCel={texts.cardCel} telefono2="+56 9 92399682" cardLocation={texts.cardLocation} lavel="Coquimbo" onClick={openModalCoquimbo} />
                         <Modal isOpen={isOpenModalCoquimbo} closeModal={closeModalCoquimbo}>
                               <h1>COQUIMBO</h1>
                                <MapaCoquimboWaze/>
                                <div className='hola'>
                                   <a href='https://www.waze.com/en/live-map/directions/cl/coquimbo/coquimbo/av.-pdte.-arturo-alessandri-861?place=EjBBdi4gUGR0ZS4gQXJ0dXJvIEFsZXNzYW5kcmkgODYxLCBDb3F1aW1ibywgQ2hpbGUiMRIvChQKEglHn_Z14ciRlhFAceWeOfHNpxDdBioUChIJFY-DPN7IkZYRTu3YOwBVahE' target='_blank' rel='noreferrer' className='btn-mobile'>
                                     <button className='card-btn'> Ir al local </button>
                                   </a>
                                   <div className='iconos'>
                                       <FontAwesomeIcon icon={faPhone}/>
                                       <a href="tel:+56998856386"> 
                                          +56 9 98856386
                                       </a>{/*con tel le indico que es un telefono, este es un celular*/} 
                                   </div>
                                   <div className='iconos'>
                                       <FontAwesomeIcon icon={faMobileRetro}/>
                                       <a href="tel:+56992399682">
                                       +56 9 92399682
                                         </a>{/*con tel le indico que es un telefono, este es numero de casa */}
                                    </div>
                                </div>
                         </Modal>
                 </ul>
                 <ul className='cards__items'>
                      
                      <CardItem src="./images/iconovallenar.jpg"  cardAddress={texts.cardAddress} direccion="Erasmo escala 760b" cardOpen={texts.cardOpen} horario="09:00am 17:00pm"  cardCel={texts.cardCel} telefono2="+56 9 98856386" cardLocation={texts.cardLocation} lavel="Vallenar"  />
                     
                     <CardItem src="./images/iconocopiapo.jpg"  cardAddress={texts.cardAddress} direccion="Miguel gallo #1034" cardOpen={texts.cardOpen} horario=" Mañana:08:30am-13:30pm Tarde:14:00pm-17:00pm" cardMovil={texts.cardMovil}  telefono="+56 52 2535615 " cardCel={texts.cardCel} telefono2="+56 9 90995412" cardLocation={texts.cardLocation} lavel="Copiapo" onClick={openModalCopiapo} />
                          <Modal isOpen={isOpenModalCopiapo} closeModal={closeModalCopiapo}>
                                  <h1>COPIAPO</h1>
                                    <MapaCopiapoWaze/>
                                   <div className='hola'>
                                      <a href='https://www.waze.com/en/live-map/directions/cl/atacama/copiapo/miguel-gallo-1034?place=ChIJl3L_7m0EmJYRzNkLGYaEyBE' target='_blank' rel='noreferrer' className='btn-mobile'>
                                        <button className='card-btn'> Ir al local </button>
                                      </a>
                                      <div className='iconos'>
                                         <FontAwesomeIcon icon={faPhone}/>
                                         <a href="tel:+56990995412"> 
                                            +56 9 90995412
                                         </a>{/*con tel le indico que es un telefono, este es un celular*/} 
                                      </div>
                                      <div className='iconos'>
                                         <FontAwesomeIcon icon={faMobileRetro}/>
                                         <a href="tel:+56522535615">
                                          +56 52 2535615
                                         </a>{/*con tel le indico que es un telefono, este es numero de casa */}
                                      </div>
                                   </div>
                           </Modal>
                     <CardItem src="./images/iconoovalle.jpg" cardAddress={texts.cardAddress}  direccion="ovalle" cardOpen={texts.cardOpen} horario="09:00am 17:00pm"  cardMovil={texts.cardMovil} telefono="+56 9 98856386" cardLocation={texts.cardLocation} lavel="Ovalle" />
                         
                 </ul>
             </div>
        </div>
    </div>
  );
}

export default Cards;