import React from 'react'

const NotFoundpage = () => {
  return (
    <div>
        <h3>Pagina no encontrada</h3>
    </div>
  )
}

export default NotFoundpage;