import './Footer.css';
import React,{ useEffect } from 'react';

import {Link} from 'react-router-dom';
import logo from '../image/logo.jpg';


export default function Footer({texts}) {

const [isReadyForInstall, setIsReadyForInstall] = React.useState(false);
/*-------pwa------ este primer use effect va a esperar que pase un evento en windows en "beforeinstalllprop" */
useEffect(() => {
  window.addEventListener("beforeinstallprompt", (event) => { 
    // Prevent the mini-infobar from appearing on mobile.
    event.preventDefault();
    console.log("👍", "beforeinstallprompt", event);
    // Stash the event so it can be triggered later.
    window.deferredPrompt = event;/*cuando se genera el evento lo guardo en esta variable window.deferrePropt */
    // Remove the 'hidden' class from the install button container.
    setIsReadyForInstall(true);/*y cambia el estado de false a true */
  });
}, []);

async function downloadApp() {
  console.log("👍", "butInstall-clicked");
  const promptEvent = window.deferredPrompt;/*en caso de que se haya generado el evento se guarga aca */
  if (!promptEvent) {
    // The deferred prompt isn't available.
    console.log("oops, no prompt event guardado en window");
    return;
  }
  // Show the install prompt.
  promptEvent.prompt();/*aqui se abre la cajita */
  // Log the result
  const result = await promptEvent.userChoice; /*y aca escucho la eleccion del usuario */
  console.log("👍", "userChoice", result);
  // Reset the deferred prompt variable, since
  // prompt() can only be called once.
  window.deferredPrompt = null;
  // Hide the install button.
  setIsReadyForInstall(false);
}


  return (
    <div className="footer-container">
        <section className="social-media">
            <div className="social-media-wrap">
                 <div class='footer-logo'>
                     <Link to='/' className='social-logo'>
                        <img src={logo} alt="logo" className='App-logo2' width="65%"/>
                     </Link>
                 </div>
                 <small className="website-rights">{texts.footerAllrights}</small>
                 <div> </div>
                 <div className="social-icons">
                   <Link className="social-icon-link facebook" to="/" target="_blank" ariel-label="Facebook">
                       <i className="fab fa-facebook-f"/>
                   </Link>
                   <Link className="social-icon-link instagram" to="/" target="_blank" ariel-label="Intagram">
                       <i className="fab fa-instagram"/>
                   </Link>
                   <Link className="social-icon-link youtube" to="/" target="_blank" ariel-label="Youtube">
                       <i className="fab fa-youtube"/>
                   </Link>
                 </div>
                 <div className="boton-Instalar">
                     {isReadyForInstall && <button className="btn btn-primary" onClick={downloadApp}>Instalar</button>} {/*si si &&  isreaadyfor installa es verdadero muestrame el boton o si no no me lo muestres*/}
                 </div>
            </div>
            
        </section>
  
    </div>
  )
}
