import React from 'react'

const MapaCoquimboWaze = () => {
  return (
    <div>
      <iframe
         title="Coquimbo" 
         src="https://embed.waze.com/iframe?zoom=16&lat=-29.969495&lon=-71.337875&ct=livemap" 
         width="400"
         height="400" 
         allowfullscreen>
      </iframe>
    </div>
    
  )
}

export default MapaCoquimboWaze;