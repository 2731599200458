function CardItem(props) {
  /*<h4 className='cards__item__text'>{props.texts} </h4> */
  
  return (
    <>
      <li className='cards__item'>  
        <div className='cards__item__link' >
            <figure className='cards__item__pic-wrap' data-category={props.lavel}>
                <img className='cards__item__img' src={props.src} alt='Travel' />
            </figure>
            <div className='cards__item__info'>
                <h4 className='cards__item__text'>{props.cardAddress} {props.direccion}</h4>
                <h5 className='cards__item__text'>{props.cardOpen}{props.horario}</h5>
                <h5 className='cards__item__text'>{props.cardOpenWeek}{props.horario2}</h5>
                <h5 className='cards__item__text'>{props.cardMovil}{props.telefono}</h5>
                <h5 className='cards__item__text'>{props.cardCel}{props.telefono2}</h5>
                <h5 className='cards__item__text'>{props.igualdad}</h5>
                <div className='cards-btns'>
                  <button className='card-btn' onClick={props.onClick} >{props.cardLocation}</button>
                </div>
            </div>
            
        </div>
      </li>
    
    </>
  )
}

export default CardItem;