import {useState} from 'react';

export const useModal = (initialValue=false) => {
   /*Si lo importas asi siempre se van a llamar del mismo modo en el archivo que lo importes */
   const[isOpen,setisOpen]=useState(initialValue);
   
   const openModal=()=>setisOpen(true);

   const closeModal=()=>setisOpen(false);

   return[isOpen,openModal,closeModal];
  
};
